import React, { createRef } from 'react'
// import useCanvas from './useCanvas';

class Canvas extends React.Component {
  constructor(props){
    super(props);
    const {options} = props;
    const width = options.width||500;
    const height = options.height||500;
    const ratio = options.ratio||1;
    this.canvasRef = createRef(null);
    this.state = {
      stageWidth : width*ratio,
      stageHeight : height*ratio,
      pixelRatio : ratio,
      isPlay: true,
      context: null,
      canvas: null
    };
  }

  resize(w,h,r){
    
    if( this.state.context ){
      const width = w||500;
      const height = h||500;
      const ratio = r||1;
      this.setState({
        stageWidth : width * ratio,
        stageHeight : height*ratio,
        pixelRatio : ratio,
      });
      this.state.context.scale(ratio, ratio);
    } else {
      window.setTimeout(()=>{
        this.resize(w,h,r)
      }, 100)
    }
  }

  draw(){
    if( this.state.isPlay ){
      const {state} = this;
      if( state.context ){
        this.frameCount ++;
        this.props.drawCanvas(state.context, this.frameCount);
      }
      this.animationFrameId = window.requestAnimationFrame(this.draw.bind(this));
    }
  }

  componentDidMount(){
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
    context.scale(this.state.pixelRatio, this.state.pixelRatio);
    this.setState({
      canvas,
      context
    });
    this.frameCount = 0;
    this.draw();
  }
  // componentDidUpdate(){
  //   console.log(this.state.context)
  // }
  componentWillUnmount(){
    window.cancelAnimationFrame(this.animationFrameId);
  }

  render(){
    const {canvasRef, state} = this;
    return (
      <canvas ref={canvasRef} width={state.stageWidth} height={state.stageHeight} style={{width:'100%', height:'100%'}}/>
    )
  }
}

export default Canvas