import React , {createRef} from 'react';
import { Link } from "gatsby"
import Canvas from '../components/canvas';
function color(num) {
  /**
   * 
   * 0 ~ 1530 까지.
   * 
   * R ( 0 , 5)
   * 0 || 1275 ~ 1530
   * 255,   0,   0
   * 
   * RB ( 0 )
   * 0 ~ 255
   * 255,   0, 255
   * 
   * B ( 1 )
   * 256 ~ 510
   *   0,   0, 255
   * 
   * GB ( 2 )
   * 511 ~ 765
   *   0, 255, 255
   * 
   * G ( 3 )
   * 766 ~ 1020
   *   0, 255,   0
   * 
   * RG ( 4, 5 )
   * 1020 ~ 1275
   * 255, 255,   0
   */

  const colorIndex = num % 1536;
  const colorStep = Math.floor( colorIndex / 256 );
  const colorValue = num % 256;
  function add(step, value){
    const colorType = (step + 1) % 2 ? 'plus' : 'minus';
    if( colorType === 'plus' ){
      if( step === 0 ){
        return [255, 0, value];
      } else if( step === 2 ){
        return [0, value, 255];
      } else {
        return [value, 255, 0];
      }
    } else {
      if( step === 1 ){
        return [255-value, 0, 255];
      } else if( step === 3 ){
        return [0, 255, 255-value];
      } else {
        return [255, 255-value, 0];
      }
    }
  }
  return add(colorStep, colorValue);
}
export default class dotGradition extends React.Component {
  constructor(props){
    super(props);
    this.canvas = createRef(null);
    this.drawSet = {
      width : 20,
      height : 20,
      r: 255,
      g: 0,
      b: 0,
      a: 1,
      margin: 1
    };
  }
  resize(){
    const w = document.body.clientWidth;
    const h = document.body.clientHeight;
    const ratio = window.devicePixelRatio;
    this.canvas.current.resize(w, h, ratio);
  }
  draw(ctx, frameCount){
    const {drawSet} = this;
    const rect = ctx.canvas.getBoundingClientRect();
    const col = parseInt( rect.height / (drawSet.height + drawSet.margin), 10)+drawSet.height;
    const row = parseInt(rect.width / (drawSet.width + drawSet.margin) , 10)+drawSet.width;
    ctx.clearRect(0, 0, rect.width, rect.height);
    let i=0;
    for ( i; i<col; i++){
      const y = i * (drawSet.height + drawSet.margin);
      let j=0;
      for ( j; j<row; j++){
        const rgb = color( frameCount + i + j*10 );
        ctx.fillStyle =  `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${drawSet.a})`;
        const x = j * (drawSet.width + drawSet.margin);
        ctx.fillRect( x, y, drawSet.width, drawSet.height );
      }
    }
    ctx.fill();
  }
  componentDidMount(){
    this.resizeEvent = this.resize.bind(this);
    window.addEventListener('resize', this.resizeEvent);
    this.resize();
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.resizeEvent);
  }
  render(){
    const {draw, state} = this;
    const width = 100;
    const height = 100;
    const ratio = 1;
    return (
      <div>
        {/* <Link to="/contact">contact</Link> */}
        <Canvas drawCanvas={draw.bind(this)} options={ {width,height,ratio} } ref={this.canvas} />
        {/* <Canvas options={ {width,height,ratio} } /> */}
      </div>
    )
  }
}